import CoreRouter from '@/fw-modules/fw-core-vue/router'
import CoreRoutes from '@/fw-modules/fw-core-vue/routes'
import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'
import BaseRoutes from './routes/base'
import ManageRoutes from './routes/manage'

Vue.use(VueRouter)

const routes = [].concat(BaseRoutes, ManageRoutes, CoreRoutes)

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_ROUTER_BASE || '/',
  routes,
})

router.beforeResolve((to, from, next) => {
  return CoreRouter.beforeResolve(to, from, next)
})
router.afterEach((to, from, next) => {
  return CoreRouter.afterEach(to, from, next)
})
router.beforeEach((to, from, next) => {
  CoreRouter.beforeEach(to, from, next)
  if (store.getters.isLoggedIn) {
    const checkAfter = permissions => {
      if (to.meta && to.meta.requiresPermissions && store.getters.isLoggedIn) {
        if (!permissions || permissions.length < 1) {
          return router.push({ name: 'forbidden' })
        }
        return next()
      }
    }
    if (!store.state.session.permissionsLoaded) {
      store.state.api.base.getUserRoles().then(result => {
        store.commit('setUserPermissions', result.permissions)
        store.commit('setUserPermissionsLoaded', true)
        checkAfter(result.permissions)
      })
    } else {
      checkAfter(store.state.session.permissions)
    }
  }
})

export default router
