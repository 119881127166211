import Vue from 'vue'
import Vuex from 'vuex'

import actions from './actions'
import getters from './getters'
import modules from './modules'
import mutations from './mutations'

import CoreStoreDefaults from '@/fw-modules/fw-core-vue/store/defaults'
import ServiceMyUC from '@/services/ServiceMyUC'

Vue.use(Vuex)

const state = Object.assign(CoreStoreDefaults.state, {
  api: {
    base: ServiceMyUC,
    ignore401: false,
  },
})

export default new Vuex.Store({
  state,
  mutations,
  actions,
  modules,
  getters,
})
